import { FC, ReactNode } from 'react'
import { styled } from '@mui/material'
import { GoDotFill } from 'react-icons/go'

const MainContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    paddingLeft: 10,
})

interface SkillProps {
    children: ReactNode,
}

const Skill: FC<SkillProps> = ({
    children,
}) => {

    return (
        <MainContainer>
            <GoDotFill />
            <div>
                {children}
            </div>
        </MainContainer>
    )
}

export default Skill;