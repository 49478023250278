import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Name from '../components/Name';
import Role from '../components/Role';
import { Stack } from '@mui/material';
import ProjectSwiper from '../components/ProjectSwiper';
import greenfield01 from '../assets/greenfield/01.png'
import greenfield02 from '../assets/greenfield/02.png'
import greenfield03 from '../assets/greenfield/03.png'
import greenfield04 from '../assets/greenfield/04.png'
import greenfield05 from '../assets/greenfield/05.png'
import greenfield06 from '../assets/greenfield/06.png'
import greenfield07 from '../assets/greenfield/07.png'
import greenfield08 from '../assets/greenfield/08.png'
import greenfield09 from '../assets/greenfield/09.png'
import greenfield10 from '../assets/greenfield/10.png'
import ptracker01 from '../assets/ptracker/01.png'
import ptracker02 from '../assets/ptracker/02.png'
import ptracker03 from '../assets/ptracker/03.png'
import ptracker04 from '../assets/ptracker/04.png'
import ptracker05 from '../assets/ptracker/05.png'
import ptracker06 from '../assets/ptracker/06.jpeg'
import ptracker07 from '../assets/ptracker/07.jpeg'
import tasko1 from '../assets/tasko/01.png'
import tasko2 from '../assets/tasko/02.png'
import tasko3 from '../assets/tasko/03.png'
import tasko4 from '../assets/tasko/04.png'
import tasko5 from '../assets/tasko/05.png'
import ProjectName from '../components/ProjectName';
import Line from '../components/Line';
import Description from '../components/Description';
import { appConfig } from '../config';
import TechnologyContainer from '../components/TechnologyContainer';
import TechnologyItem from '../components/TechnologyItem';
import jsLogo from '../assets/logos/js.png'
import tsLogo from '../assets/logos/ts.png'
import mongodbLogo from '../assets/logos/mongodb.png'
import gitLogo from '../assets/logos/git.png'
import gitlabLogo from '../assets/logos/gitlab.png'
import nodeLogo from '../assets/logos/node.png'
import nestLogo from '../assets/logos/nest.png'
import swaggerLogo from '../assets/logos/swagger.png'
import postmanLogo from '../assets/logos/postman.png'
import onseignalLogo from '../assets/logos/onesignal.png'
import sqlLogo from '../assets/logos/sql.png'
import reactLogo from '../assets/logos/react.png'
import htmlLogo from '../assets/logos/html.png'
import cssLogo from '../assets/logos/css.png'
import postgresqlLogo from '../assets/logos/postgresql.png'
import sqliteLogo from '../assets/logos/sqlite.png'
import graphqlLogo from '../assets/logos/graphql.png'
import restapiLogo from '../assets/logos/restapi.png'
import ScrollFadeComponent from '../components/ScrollFadeComponents';
import { Label } from '../components/Label';
import Container from '../components/Container';
import MenuButton from '../components/MenuButton';
import StoreButton from '../components/StoreButton';
import MainPhoto from '../components/MainPhoto';
import Skill from '../components/Skill';


interface HomeProps {

}

const Home: FC<HomeProps> = (props) => {
    const contactSectionRef = useRef<HTMLDivElement | null>(null);
    const techSkillsSectionRef = useRef<HTMLDivElement | null>(null);
    const softSkillsSectionRef = useRef<HTMLDivElement | null>(null);
    const aboutSectionRef = useRef<HTMLDivElement | null>(null);
    const technologiesSectionRef = useRef<HTMLDivElement | null>(null);
    const projectsSectionRef = useRef<HTMLDivElement | null>(null);
    const [screenX, setScreenX] = useState(2000)
    const sidePadding = useMemo((): number => {
        if (screenX < 500) return 2
        if (screenX < 1000) return 4
        if (screenX < 1400) return 10
        return 12
    }, [screenX])

    const getCurrentScreenSize = () => {
        setScreenX(window.innerWidth)
    }


    const scrollToContact = () => {
        if (contactSectionRef.current) {
            contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToAbout = () => {
        if (aboutSectionRef.current) {
            aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToTechnologies = () => {
        if (technologiesSectionRef.current) {
            technologiesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToProjects = () => {
        if (projectsSectionRef.current) {
            projectsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToTechSkills = () => {
        if (techSkillsSectionRef.current) {
            techSkillsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToSoftSkills = () => {
        if (softSkillsSectionRef.current) {
            softSkillsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }


    useEffect(() => {
        getCurrentScreenSize()
        window.addEventListener('resize', getCurrentScreenSize);
        return () => {
            window.removeEventListener('resize', getCurrentScreenSize)
        }
    }, [])


    return (
        <Container maxWidth={850}>
            <Stack
                spacing={3}
                padding={sidePadding}
                paddingTop={4}
                paddingBottom={50}
            >
                <MenuButton
                    items={[
                        {
                            name: 'O mnie',
                            onClick: scrollToAbout,
                        },
                        {
                            name: 'Technologie',
                            onClick: scrollToTechnologies,
                        },
                        {
                            name: 'Projekty',
                            onClick: scrollToProjects,
                        },
                        {
                            name: 'Umiejętności techniczne',
                            onClick: scrollToTechSkills,
                        },
                        {
                            name: 'Umiejętności miękkie',
                            onClick: scrollToSoftSkills,
                        },
                        {
                            name: 'Kontakt',
                            onClick: scrollToContact,
                        },
                    ]}
                />

                <Stack
                    alignItems='center'
                    padding={"24px 0"}
                    ref={aboutSectionRef}
                >
                    <Name>
                        Karol Wilczak
                    </Name>
                    <Role>
                       junior backend developer
                    </Role>
                </Stack>
                <Line />
                <Label>
                    Coś o mnie
                </Label>
                <MainPhoto />
                <Line />
                <div ref={technologiesSectionRef} />
                <Label>
                    Technologie
                </Label>
                <TechnologyContainer>
                    <TechnologyItem
                        name='JavaScript'
                        img={jsLogo}
                    />
                    <TechnologyItem
                        name='TypeScript'
                        img={tsLogo}
                    />
                    <TechnologyItem
                        name='Nodejs'
                        img={nodeLogo}
                    />
                    <TechnologyItem
                        name='Nestjs'
                        img={nestLogo}
                    />
                    <TechnologyItem
                        name='React'
                        img={reactLogo}
                    />
                </TechnologyContainer>
                <TechnologyContainer>
                    <TechnologyItem
                        name='Rest API'
                        img={restapiLogo}
                    />
                    <TechnologyItem
                        name='GraphQL'
                        img={graphqlLogo}
                    />
                </TechnologyContainer>
                <TechnologyContainer>
                    <TechnologyItem
                        name='MongoDB'
                        img={mongodbLogo}
                    />
                    <TechnologyItem
                        name='SQL'
                        img={sqlLogo}
                    />
                    <TechnologyItem
                        name='PostgreSQL'
                        img={postgresqlLogo}
                    />
                    <TechnologyItem
                        name='SQLite'
                        img={sqliteLogo}
                    />
                </TechnologyContainer>
                <TechnologyContainer>
                    <TechnologyItem
                        name='Git'
                        img={gitLogo}
                    />
                    <TechnologyItem
                        name='GitLab'
                        img={gitlabLogo}
                    />
                </TechnologyContainer>

                <TechnologyContainer>
                    <TechnologyItem
                        name='Postman'
                        img={postmanLogo}
                    />
                    <TechnologyItem
                        name='Swagger'
                        img={swaggerLogo}
                    />
                </TechnologyContainer>

                <TechnologyContainer>
                    <TechnologyItem
                        name='OneSignal'
                        img={onseignalLogo}
                    />
                </TechnologyContainer>

                <TechnologyContainer>
                    <TechnologyItem
                        name='HTML'
                        img={htmlLogo}
                    />
                    <TechnologyItem
                        name='CSS'
                        img={cssLogo}
                    />
                </TechnologyContainer>

                <Line />

                <div ref={projectsSectionRef} />

                <ScrollFadeComponent>
                    <ProjectName>
                        {appConfig.greenfieldName}
                    </ProjectName>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <Description>
                        Aplikacja mobilna, stworzona aby ułatwić zarządzanie gospodarstwem rolniczym.
                        <br />
                        <br />
                        Projekt jest stale rozwijany, a ja jestem odpowiedzialny za jego backend. Oprócz typowego stwórz, edytuj, usuń, pobierz, aplikacja wymagała ode mnie obsługi autoryzacji użytkowników, implementacji powiadomień, oraz wywoływania wydarzeń w zaplanowanej przyszłości (np. tydzień przed terminem wycielenia się krowy).
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (Nestjs, MongoDB, OneSignal)
                    </Description>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <StoreButton
                        iosUrl={'https://apps.apple.com/us/app/greenfield/id6461776009'}
                        andridUrl={'https://play.google.com/store/apps/details?id=com.greenfield.mobile'}
                    />
                </ScrollFadeComponent>

                <ProjectSwiper
                    id='greenfield'
                    images={[
                        greenfield01,
                        greenfield02,
                        greenfield03,
                        greenfield04,
                        greenfield05,
                        greenfield06,
                        greenfield07,
                        greenfield08,
                        greenfield09,
                        greenfield10,
                    ]}
                />
                <Line />
                <ScrollFadeComponent>
                    <ProjectName>
                        PTRACKER
                    </ProjectName>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <Description>
                        System do zarządzania produkcją w fabrykach sprzętu wojskowego.
                        <br />
                        <br />
                        Pracuję przy tym projekcie po godzinach dla firmy HXS z Krakowa. Jest on tworzony w celu automatyzacji pracy zakładu. Pomaga zarządzać pracownikami i aktywami firmy, oraz tworzyć schematy procesów produkcyjnych, które potem są realizowane przez pracowników.
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (Nestjs, MongoDB, OneSignal, Swagger)
                    </Description>
                </ScrollFadeComponent>

                <ProjectSwiper
                    id='ptracker'
                    images={[
                        ptracker01,
                        ptracker02,
                        ptracker06,
                        ptracker07,
                        ptracker03,
                        ptracker04,
                        ptracker05,
                    ]}
                />
                <Line />
                <ScrollFadeComponent>
                    <ProjectName>
                        Tasko
                    </ProjectName>
                </ScrollFadeComponent>
                <ScrollFadeComponent>
                    <Description>
                        Aplikacja mobilna stworzona, aby ułatwić zarządzanie domowymi obowiązkami. Pozwala zbierać się w grupy (np. rodzinę) i dodawać na grupie zadania. Za wykonywanie zadań można nagradzać innych punktami, które można potem wydać na różne nagrody.
                        <br />
                        <br />
                        Nie jestem autorem tego projektu, ale pracuję nad jego rozwojem, dodając nowe funkcjonalności po stronie backendu.
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (Nestjs, MongoDB, OneSignal)
                    </Description>
                </ScrollFadeComponent>
                <ScrollFadeComponent>
                    <StoreButton
                        iosUrl={'https://apps.apple.com/us/app/tasko/id6446377529'}
                        andridUrl={"https://play.google.com/store/apps/details?id=com.tasko"}
                    />
                </ScrollFadeComponent>
                <ProjectSwiper
                    id='tasko'
                    images={[
                        tasko3,
                        tasko1,
                        tasko2,
                        tasko4,
                        tasko5
                    ]}
                />
                <Line />
                <Stack spacing={2}>
                    <Label ref={techSkillsSectionRef}>
                        Umiejętności techniczne
                    </Label>
                    <Skill>
                        Tworzenie end-pointów z nastawieniem na maksymalną efektywność i
                        oszczędność serwera
                    </Skill>
                    <Skill>
                        Obsługa płatności online
                    </Skill>
                    <Skill>
                        Projektowanie bazy danych przed rozpoczęciem pracy nad projektem
                    </Skill>
                    <Skill>
                        Obsługa sytemu kontroli wersji Git wraz z serwisem GitLab
                    </Skill>
                    <Skill>
                        Generowanie czytelnej dokumentacji API przy użyciu Postmana lub
                        Swaggera
                    </Skill>
                    <Skill>
                        Wysyłanie powiadomień typu Push z użyciem OneSignal oraz maili
                    </Skill>
                    <Skill>
                        Zarządzanie magazynowaniem i hostowaniem plików
                    </Skill>
                </Stack>
                <Line />
                <Stack spacing={2}>
                    <Label ref={softSkillsSectionRef}>
                        Umiejętności miękkie
                    </Label>
                    <Skill>
                        Organizacja projektu ze szczegółowym podziałem pracy na zadania
                    </Skill>
                    <Skill>
                        Dokładne estymowanie czasu pracy
                    </Skill>
                    <Skill>
                        Bezproblemowa komunikacja z klientami i innymi członkami zespołu
                    </Skill>
                    <Skill>
                        Komunikowanie pomysłów i obiekcji dotyczących projektów
                    </Skill>
                </Stack>
                <Line />
                <Label ref={contactSectionRef}>
                    Kontakt
                </Label>
                <ScrollFadeComponent>
                    <Description>
                        tel: 519 619 378
                        <br />
                        <br />
                        email: karol.wilczak@vp.pl
                    </Description>
                </ScrollFadeComponent>
            </Stack>
        </Container>
    )
}

export default Home;