// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --light: #e4e8f0;
  --line-color: #cfcfcf;
  --grey: #69707a;
}


body {
  background-color: black;
  color: white;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  max-width: 240px;
  aspect-ratio: 1/2.2;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 30px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: #ffffff !important;
}

.swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}

.scroll-fade-component {
  opacity: 0;
  transform: translateX(20px); /* Opcjonalnie: animacja przesunięcia w górę */
  transition: opacity 0.5s, transform 0.5s;
}

.scroll-fade-component.visible {
  opacity: 1;
  transform: translateX(0);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;AACjB;;;AAGA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;EACV,2BAA2B,EAAE,8CAA8C;EAC3E,wCAAwC;AAC1C;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[":root{\n  --light: #e4e8f0;\n  --line-color: #cfcfcf;\n  --grey: #69707a;\n}\n\n\nbody {\n  background-color: black;\n  color: white;\n}\n\n.swiper {\n  width: 100%;\n  padding-top: 50px;\n  padding-bottom: 50px;\n}\n\n.swiper-slide {\n  background-position: center;\n  background-size: cover;\n  width: 100%;\n  max-width: 240px;\n  aspect-ratio: 1/2.2;\n  overflow: hidden;\n  border-radius: 16px;\n  margin-bottom: 30px;\n}\n\n.swiper-slide img {\n  display: block;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.swiper-pagination-bullet {\n  background-color: #ffffff !important;\n}\n\n.swiper-pagination-bullet-active {\n  background-color: #ffffff !important;\n}\n\n.scroll-fade-component {\n  opacity: 0;\n  transform: translateX(20px); /* Opcjonalnie: animacja przesunięcia w górę */\n  transition: opacity 0.5s, transform 0.5s;\n}\n\n.scroll-fade-component.visible {\n  opacity: 1;\n  transform: translateX(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
